import { default as adminsQWTFCgddiHMeta } from "/tmp/build_21c2d63e/pages/administration/admins.vue?macro=true";
import { default as _91id_93De5rpyPBj8Meta } from "/tmp/build_21c2d63e/pages/administration/billing/[id].vue?macro=true";
import { default as indexplUgamrJE2Meta } from "/tmp/build_21c2d63e/pages/administration/billing/index.vue?macro=true";
import { default as bookingsqwdxllzaOaMeta } from "/tmp/build_21c2d63e/pages/administration/bookings.vue?macro=true";
import { default as exportssgEgkVBB6EMeta } from "/tmp/build_21c2d63e/pages/administration/exports.vue?macro=true";
import { default as fidelityGgJzP498wpMeta } from "/tmp/build_21c2d63e/pages/administration/fidelity.vue?macro=true";
import { default as invoices0tR3PDmQFNMeta } from "/tmp/build_21c2d63e/pages/administration/invoices.vue?macro=true";
import { default as policybLwk9SpRV8Meta } from "/tmp/build_21c2d63e/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_93LpNmj1cGQOMeta } from "/tmp/build_21c2d63e/pages/administration/payment/[id].vue?macro=true";
import { default as index361xtcAPagMeta } from "/tmp/build_21c2d63e/pages/administration/payment/index.vue?macro=true";
import { default as co28RIG2nGlycMeta } from "/tmp/build_21c2d63e/pages/administration/statistics/co2.vue?macro=true";
import { default as indexmPrYudny0DMeta } from "/tmp/build_21c2d63e/pages/administration/statistics/index.vue?macro=true";
import { default as indexwKAZbVoDngMeta } from "/tmp/build_21c2d63e/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitationWtRXgGz0aNMeta } from "/tmp/build_21c2d63e/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as indexIYWmYfVzLKMeta } from "/tmp/build_21c2d63e/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modifyvGvwJe9XwlMeta } from "/tmp/build_21c2d63e/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorsEoTVppaj7wMeta } from "/tmp/build_21c2d63e/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_934DQmrvrZr1Meta } from "/tmp/build_21c2d63e/pages/administration/teams/[teamId].vue?macro=true";
import { default as indexVUgsCILqpAMeta } from "/tmp/build_21c2d63e/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labelsUtnoaUVlixMeta } from "/tmp/build_21c2d63e/pages/administration/trip-labels.vue?macro=true";
import { default as administrationUG4jOuvfrOMeta } from "/tmp/build_21c2d63e/pages/administration.vue?macro=true";
import { default as swileh7DHmOmULMMeta } from "/tmp/build_21c2d63e/pages/auth/swile.vue?macro=true";
import { default as indexkVGe4Tc4KMMeta } from "/tmp/build_21c2d63e/pages/bookings/index.vue?macro=true";
import { default as indexEqCutYT4zpMeta } from "/tmp/build_21c2d63e/pages/bookings/validator/index.vue?macro=true";
import { default as pendingevmyebu49WMeta } from "/tmp/build_21c2d63e/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_93htBTZJUMWcMeta } from "/tmp/build_21c2d63e/pages/car/[id].vue?macro=true";
import { default as fidelityyXOaAOMZpnMeta } from "/tmp/build_21c2d63e/pages/fidelity.vue?macro=true";
import { default as exchangeMl00d0KCqvMeta } from "/tmp/build_21c2d63e/pages/flight/[id]/exchange.vue?macro=true";
import { default as faresshd7q4UJ3aMeta } from "/tmp/build_21c2d63e/pages/flight/[id]/fares.vue?macro=true";
import { default as indexzJt3Q6XnTXMeta } from "/tmp/build_21c2d63e/pages/flight/[id]/index.vue?macro=true";
import { default as forgotvbAZ26FhVRMeta } from "/tmp/build_21c2d63e/pages/forgot.vue?macro=true";
import { default as get_45swile_45appntWupTKoEZMeta } from "/tmp/build_21c2d63e/pages/get-swile-app.vue?macro=true";
import { default as helpVdRxYBQb4MMeta } from "/tmp/build_21c2d63e/pages/help.vue?macro=true";
import { default as _91id_93THsjQX3cbaMeta } from "/tmp/build_21c2d63e/pages/hotel/[id].vue?macro=true";
import { default as _91id_93mqPxzS936HMeta } from "/tmp/build_21c2d63e/pages/impersonate/[id].vue?macro=true";
import { default as indexrfo3iGgHXPMeta } from "/tmp/build_21c2d63e/pages/index.vue?macro=true";
import { default as _91suffix_937FMQiXMd3IMeta } from "/tmp/build_21c2d63e/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_938s5khvxcwgMeta } from "/tmp/build_21c2d63e/pages/invite/[token].vue?macro=true";
import { default as _91id_93Du23y0rijxMeta } from "/tmp/build_21c2d63e/pages/journeys/[id].vue?macro=true";
import { default as indexOuoosElaWRMeta } from "/tmp/build_21c2d63e/pages/journeys/index.vue?macro=true";
import { default as membershJhiamzpu2Meta } from "/tmp/build_21c2d63e/pages/myteam/members.vue?macro=true";
import { default as policylybbS0ZzvTMeta } from "/tmp/build_21c2d63e/pages/myteam/policy.vue?macro=true";
import { default as validatorsDwLHTq52abMeta } from "/tmp/build_21c2d63e/pages/myteam/validators.vue?macro=true";
import { default as myteamkvquwKcFBOMeta } from "/tmp/build_21c2d63e/pages/myteam.vue?macro=true";
import { default as new_45userUQbBV7EvbKMeta } from "/tmp/build_21c2d63e/pages/new-user.vue?macro=true";
import { default as passwordFcbieSNoRZMeta } from "/tmp/build_21c2d63e/pages/password.vue?macro=true";
import { default as indexxaCXiZpTvEMeta } from "/tmp/build_21c2d63e/pages/search/car/[search_id]/index.vue?macro=true";
import { default as indexIJglQI6vEcMeta } from "/tmp/build_21c2d63e/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93gRKG7POKmZMeta } from "/tmp/build_21c2d63e/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_93j6h2H52LjjMeta } from "/tmp/build_21c2d63e/pages/search/flight/[search_id].vue?macro=true";
import { default as indexymKBNLxcBmMeta } from "/tmp/build_21c2d63e/pages/search/flight/index.vue?macro=true";
import { default as indexIWb29eDEBIMeta } from "/tmp/build_21c2d63e/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_93OTNo8ndGveMeta } from "/tmp/build_21c2d63e/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as indexoMJ4kape66Meta } from "/tmp/build_21c2d63e/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93piWwQYRrmuMeta } from "/tmp/build_21c2d63e/pages/search/train/[search_id].vue?macro=true";
import { default as indexMyIWaNNSJrMeta } from "/tmp/build_21c2d63e/pages/search/train/index.vue?macro=true";
import { default as searchw6Zh4Ql3HNMeta } from "/tmp/build_21c2d63e/pages/search.vue?macro=true";
import { default as index8FW7vmDr1MMeta } from "/tmp/build_21c2d63e/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationJ2iE1UnEPcMeta } from "/tmp/build_21c2d63e/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policyf4U8wdvjjQMeta } from "/tmp/build_21c2d63e/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validatorsNjbF49nduyMeta } from "/tmp/build_21c2d63e/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93EashMukNkCMeta } from "/tmp/build_21c2d63e/pages/teams/[teamId].vue?macro=true";
import { default as indexX6f534YwQvMeta } from "/tmp/build_21c2d63e/pages/teams/index.vue?macro=true";
import { default as teamsxuKPqARcyhMeta } from "/tmp/build_21c2d63e/pages/teams.vue?macro=true";
import { default as indexfjc53ysRtDMeta } from "/tmp/build_21c2d63e/pages/train/[id]/cancel/index.vue?macro=true";
import { default as index1aRenWxygaMeta } from "/tmp/build_21c2d63e/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as indexvW7LSMidEjMeta } from "/tmp/build_21c2d63e/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexea0BqSBAVZMeta } from "/tmp/build_21c2d63e/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as index9H9wg4L5dkMeta } from "/tmp/build_21c2d63e/pages/train/[id]/index.vue?macro=true";
import { default as driver_45licenseDy06wmP5abMeta } from "/tmp/build_21c2d63e/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelitypgbbPvZIpTMeta } from "/tmp/build_21c2d63e/pages/users/[id]/fidelity.vue?macro=true";
import { default as indexmka2Bj5XZUMeta } from "/tmp/build_21c2d63e/pages/users/[id]/index.vue?macro=true";
import { default as passport2IpFmJxhpNMeta } from "/tmp/build_21c2d63e/pages/users/[id]/passport.vue?macro=true";
import { default as passwordZ8jaMcFcduMeta } from "/tmp/build_21c2d63e/pages/users/[id]/password.vue?macro=true";
import { default as _91id_93uH7qMSDE0zMeta } from "/tmp/build_21c2d63e/pages/users/[id].vue?macro=true";
import { default as indexUVtKDkA7Q9Meta } from "/tmp/build_21c2d63e/pages/users/index.vue?macro=true";
import { default as vouchersuaaMl6qaIgMeta } from "/tmp/build_21c2d63e/pages/vouchers.vue?macro=true";
import { default as component_45stubKPnLTtk0wEMeta } from "/tmp/build_21c2d63e/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubKPnLTtk0wE } from "/tmp/build_21c2d63e/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationUG4jOuvfrOMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/administration.vue"),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_21c2d63e/pages/administration/admins.vue")
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_21c2d63e/pages/administration/billing/[id].vue")
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_21c2d63e/pages/administration/billing/index.vue")
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_21c2d63e/pages/administration/bookings.vue")
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_21c2d63e/pages/administration/exports.vue")
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_21c2d63e/pages/administration/fidelity.vue")
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_21c2d63e/pages/administration/invoices.vue")
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_21c2d63e/pages/administration/organization/policy.vue")
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_21c2d63e/pages/administration/payment/[id].vue")
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_21c2d63e/pages/administration/payment/index.vue")
  },
  {
    name: "administration-statistics-co2",
    path: "statistics/co2",
    component: () => import("/tmp/build_21c2d63e/pages/administration/statistics/co2.vue")
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_21c2d63e/pages/administration/statistics/index.vue")
  },
  {
    name: _91teamId_934DQmrvrZr1Meta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_21c2d63e/pages/administration/teams/[teamId].vue"),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: indexwKAZbVoDngMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/administration/teams/[teamId]/index.vue")
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_21c2d63e/pages/administration/teams/[teamId]/invitation.vue")
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_21c2d63e/pages/administration/teams/[teamId]/policy/index.vue")
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modifyvGvwJe9XwlMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/administration/teams/[teamId]/policy/modify.vue")
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_21c2d63e/pages/administration/teams/[teamId]/validators.vue")
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_21c2d63e/pages/administration/teams/index.vue")
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_21c2d63e/pages/administration/trip-labels.vue")
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swileh7DHmOmULMMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/auth/swile.vue")
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_21c2d63e/pages/bookings/index.vue")
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexEqCutYT4zpMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/bookings/validator/index.vue")
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pendingevmyebu49WMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/bookings/validator/pending.vue")
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_21c2d63e/pages/car/[id].vue")
  },
  {
    name: "fidelity",
    path: "/fidelity",
    component: () => import("/tmp/build_21c2d63e/pages/fidelity.vue")
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_21c2d63e/pages/flight/[id]/exchange.vue")
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_21c2d63e/pages/flight/[id]/fares.vue")
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_21c2d63e/pages/flight/[id]/index.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotvbAZ26FhVRMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/forgot.vue")
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45appntWupTKoEZMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/get-swile-app.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_21c2d63e/pages/help.vue")
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_21c2d63e/pages/hotel/[id].vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_21c2d63e/pages/impersonate/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexrfo3iGgHXPMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/index.vue")
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_937FMQiXMd3IMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/invitation/[suffix].vue")
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_938s5khvxcwgMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/invite/[token].vue")
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_21c2d63e/pages/journeys/[id].vue")
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_21c2d63e/pages/journeys/index.vue")
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_21c2d63e/pages/myteam.vue"),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_21c2d63e/pages/myteam/members.vue")
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_21c2d63e/pages/myteam/policy.vue")
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_21c2d63e/pages/myteam/validators.vue")
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45userUQbBV7EvbKMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/new-user.vue")
  },
  {
    name: "password",
    path: "/password",
    meta: passwordFcbieSNoRZMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/password.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_21c2d63e/pages/search.vue"),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_21c2d63e/pages/search/car/[search_id]/index.vue")
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_21c2d63e/pages/search/car/index.vue")
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_21c2d63e/pages/search/car/init/[search_id].vue")
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_21c2d63e/pages/search/flight/[search_id].vue")
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_21c2d63e/pages/search/flight/index.vue")
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_21c2d63e/pages/search/hotel/[search_id]/index.vue")
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_21c2d63e/pages/search/hotel/[search_id]/rooms/[hotelIds].vue")
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_21c2d63e/pages/search/hotel/index.vue")
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_21c2d63e/pages/search/train/[search_id].vue")
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_21c2d63e/pages/search/train/index.vue")
  }
]
  },
  {
    name: teamsxuKPqARcyhMeta?.name,
    path: "/teams",
    meta: teamsxuKPqARcyhMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/teams.vue"),
    children: [
  {
    name: _91teamId_93EashMukNkCMeta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_21c2d63e/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_21c2d63e/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_21c2d63e/pages/teams/[teamId]/invitation.vue")
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_21c2d63e/pages/teams/[teamId]/policy.vue")
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_21c2d63e/pages/teams/[teamId]/validators.vue")
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_21c2d63e/pages/teams/index.vue")
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_21c2d63e/pages/train/[id]/cancel/index.vue")
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_21c2d63e/pages/train/[id]/exchange/changeable-selection/index.vue")
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_21c2d63e/pages/train/[id]/exchange/section-selection/index.vue")
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_21c2d63e/pages/train/[id]/exchange/train-selection/index.vue")
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_21c2d63e/pages/train/[id]/index.vue")
  },
  {
    name: _91id_93uH7qMSDE0zMeta?.name,
    path: "/users/:id()",
    meta: _91id_93uH7qMSDE0zMeta || {},
    component: () => import("/tmp/build_21c2d63e/pages/users/[id].vue"),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_21c2d63e/pages/users/[id]/driver-license.vue")
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_21c2d63e/pages/users/[id]/fidelity.vue")
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_21c2d63e/pages/users/[id]/index.vue")
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_21c2d63e/pages/users/[id]/passport.vue")
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_21c2d63e/pages/users/[id]/password.vue")
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_21c2d63e/pages/users/index.vue")
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_21c2d63e/pages/vouchers.vue")
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_21c2d63e/pages/vouchers.vue")
  },
  {
    name: component_45stubKPnLTtk0wEMeta?.name,
    path: "/metrics",
    component: component_45stubKPnLTtk0wE
  },
  {
    name: component_45stubKPnLTtk0wEMeta?.name,
    path: "/en",
    component: component_45stubKPnLTtk0wE
  },
  {
    name: component_45stubKPnLTtk0wEMeta?.name,
    path: "/fr",
    component: component_45stubKPnLTtk0wE
  },
  {
    name: component_45stubKPnLTtk0wEMeta?.name,
    path: "/pt-BR",
    component: component_45stubKPnLTtk0wE
  },
  {
    name: component_45stubKPnLTtk0wEMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stubKPnLTtk0wE
  },
  {
    name: component_45stubKPnLTtk0wEMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stubKPnLTtk0wE
  },
  {
    name: component_45stubKPnLTtk0wEMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stubKPnLTtk0wE
  }
]