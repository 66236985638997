import routerOptions0 from "/tmp/build_21c2d63e/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/tmp/build_21c2d63e/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}